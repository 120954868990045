import { domoscioAccess } from '../api/domoscio_access'

interface TBody {
  student_uid: string
  objective_id?: number[]
}

// Get objective scoped knowledge_nodes infos
export async function getMemorizeProgression(
  studentUid: string,
  lang: string,
  objectives?: number[]
) {
  const body = {
    student_uid: studentUid,
    lang
  } as TBody
  if (objectives) {
    body.objective_id = objectives
  }

  return await domoscioAccess.getMemorizeProgression(body)
}

// Get student scoped objective_students infos
export async function getObjectiveStudents(studentUid: string) {
  return await domoscioAccess.getObjectiveStudents({
    student_uid: studentUid
  })
}
