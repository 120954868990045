import I18n from '../../../locales/I18n'
import { ProgressLevels, ReviewStatus } from './types'

export const formatCourseDate = (date: Date) => {
  // Extract the day, month, and year
  const day = String(date.getUTCDate()).padStart(2, '0')
  const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-based
  const year = date.getUTCFullYear()

  // Format the date as "dd/mm/yyyy"
  return `${day}/${month}/${year}`
}

export const progressFormater = (data: ProgressLevels, filteredSkills?: number[]) => {
  const statusTransformer = {
    pending: 'to_do' as const,
    late: 'late' as const
  }

  const getPotentialLevel = (status: ReviewStatus, value: number, isActive: boolean) => {
    if (status === 'uptodate' || !value || !isActive) {
      return undefined
    } else {
      return {
        status: statusTransformer[status],
        value: Math.floor(value)
      }
    }
  }
  return data.map(objective => {
    const name = objective.name
    const percentage = Math.floor(objective.average_memory_level)
    const date = formatCourseDate(new Date(objective.subscription_date))
    const isActive = objective.active
    const percentage2 = getPotentialLevel(
      objective.status,
      objective.potential_average_memory_level,
      objective.active
    )
    const skills = objective.knowledge_nodes
      .filter(kn => (filteredSkills ? filteredSkills?.includes(kn.id) : true))
      .map(skill => {
        return {
          name: skill.name,
          percentage: Math.floor(skill.memory_level),
          isActive: skill.active,
          helpText: skill.active ? undefined : I18n.t('home.memo_inactive_tooltip'),
          percentage2: getPotentialLevel(skill.status, skill.potential_memory_level, skill.active)
        }
      })
    return {
      name,
      date,
      isActive,
      percentage,
      percentage2,
      skills
    }
  })
}
