import React, { useEffect, useState } from 'react'
import { getMemorizeProgression } from '../../../core/fetchObjectiveDatas'
import { Loader } from '@domoscio/domoscio-ui'
import ProgressData from '../../../components/home/progress/ProgressData'
import './ProgressRiseUpTsr.scss'
import { ProgressLevels } from '../../../components/home/progress/types'
import { Result } from '../../learning_session/LearningSession'
import { ActionResult } from '../../learning_session/LearningAction'
import { User } from '../../../contexts/parameters/user'
import I18n from '../../../locales/I18n'
interface Props {
  studiedObjectives: number[]
  results: Array<Result | ActionResult>
}
export const ProgressRiseUpTsr = ({ studiedObjectives, results }: Props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<ProgressLevels | null>(null)
  const user = React.useContext(User.State)

  const studiedSkills = results.map(result => result?.content?.review?.knowledge_node_id)
  console.log({ studiedSkills, studiedObjectives })

  useEffect(() => {
    const lang = I18n.locale
    getMemorizeProgression(user.uid || '123', lang, studiedObjectives)
      .then(data => {
        setData(data.objectives)
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className='progressRiseUpTsr'>
      <ProgressData progressData={data ? data : []} isTsr={true} filteredSkills={studiedSkills} />
    </div>
  )
}
