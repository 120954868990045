/* eslint-disable */
/**
 * @file Manages the Init app methods.
 * @author Matthieu Mugnier
 */

import * as React from 'react'
import { ThemeProvider } from 'react-jss'
import { useLocation, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { domUtils, domToolkit } from '@domoscio/domoscio-sdk-js'
import { Loader, DomUILocales } from '@domoscio/domoscio-ui'
import * as Errors from '../components/Errors/ErrorScreens'
import { Client, Session, Theme, User } from '../contexts/parameters/parametersWrapper'
import { useProgress } from '../contexts/progressData/progressData'
import { domoscioAccess } from '../api/domoscio_access'
import { useInitialization } from './useInitialization'
import { getStorage } from '../storage'
import I18n from '../locales/I18n'
import AppLoader from '../components/app/AppLoader'
import * as Sentry from '@sentry/react'
import { getObjectiveStudents } from './fetchObjectiveDatas'
import {
  ClientParameters,
  ColorParameters,
  SessionParameters,
  StudentGroup,
  StudentParameters,
  Tscreens
} from '../types/parameters'
import ReturnToHomeButton from '../components/Errors/ErrorWrappers/ReturnToHomeButton'

export interface TAllParameters {
  session_parameters: SessionParameters
  client_parameters: ClientParameters
  student_parameters: StudentParameters
  student_groups: StudentGroup[]
  body: {
    message: string
  }
  statusCode: number
}

/**
 * Initialize app client & user
 *
 * @category Components
 */
interface Props {
  children: React.ReactNode
}
interface User extends Partial<StudentParameters> {
  isRiseUpUser?: boolean
}
function Initializers({ children }: Props) {
  // Get storage from browser context (try if sessionStorage is available)
  const storage = getStorage()
  // Contexts
  const client = React.useContext(Client.State)
  const clientDispatch = React.useContext(Client.Dispatch)
  const theme = React.useContext(Theme.State)
  const themeDispatch = React.useContext(Theme.Dispatch)
  const userDispatch = React.useContext(User.Dispatch)
  const sessionDispatch = React.useContext(Session.Dispatch)
  const { setProgressData, objectives } = useProgress()

  // Hooks
  const [user, setUser] = React.useState<User>({})
  const [isError, setIsError] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()

  const getQuery = () => new URLSearchParams(location.search)
  const setTheme = (theme: ColorParameters) => {
    themeDispatch(theme)
    storage.setItem('theme', JSON.stringify(theme))
  }

  const debugTrace = (trace: string) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log(trace)
    }
  }

  /**
   * Append custom theme <style> tag to document.head
   */
  const appendTheme = (theme: ColorParameters) => {
    const style = document.createElement('style')
    document.head.appendChild(style)
    style.innerHTML = domToolkit.themeToCss(theme)
  }

  /**
   * Apply parameters to LXP
   */
  const applyParameters = async (
    parameters: TAllParameters,
    isClassicWorkflow: boolean,
    isUnsubscribeMode: boolean
  ) => {
    if (parameters) {
      Sentry.setUser({
        id: parameters?.student_parameters?.uid,
        debugData: storage?.getItem('accesstoken')?.slice(0, 10)
      })
      const isRiseUpUser = parameters?.client_parameters?.riseup_client

      // For ru users use customisation color instead of client color
      if (
        isRiseUpUser &&
        parameters?.client_parameters &&
        parameters?.student_parameters?.user_parameter?.customisation?.primary_color
      ) {
        parameters.client_parameters.mqb_primary_color =
          parameters?.student_parameters?.user_parameter?.customisation?.primary_color
      }
      const theme = domUtils.parametersToTheme(parameters?.client_parameters) as ColorParameters
      setTheme(theme)
      appendTheme(theme)
      I18n.locale = (parameters?.student_parameters?.account.lang || 'en').toLowerCase()
      DomUILocales.locale = I18n.locale
      const studentParams = parameters?.student_parameters
      const formatedUser = {
        ...studentParams,
        ...studentParams?.user_parameter,
        ...studentParams?.account,
        id: studentParams?.id,
        replayTour: {} as Tscreens
      }
      userDispatch(formatedUser)
      setUser(formatedUser)
      sessionDispatch(parameters?.session_parameters)
      clientDispatch(parameters?.client_parameters || {})
      // Redirect to onboarding if mobile app is used and user mail is not known
      const userMail = formatedUser?.email || false
      const mobileAuthorized = parameters.client_parameters.mobile_app
      const isUserSeenOnboarding = formatedUser?.onboarding?.lxp_lock?.download_screen

      // 🚨 if isClassicWorkflow is false => to display theme on subscription message redirectuser  to  "/"
      if (isUnsubscribeMode) {
        navigate('/unsubscribe')
      } else if (
        !mobileAuthorized ||
        !isClassicWorkflow ||
        isUserSeenOnboarding ||
        userMail ||
        isRiseUpUser
      ) {
        setUser(formatedUser => ({ ...formatedUser, isRiseUpUser: true }))
        getObjectiveStudents(formatedUser.uid).then(objectives => {
          setProgressData(prev => ({
            ...prev,
            objectives
          }))
          navigate('/')
        })
      } else {
        navigate('/onboarding')
      }
    }
  }

  /**
   * Get access_token param
   */
  const getAccessToken = () => {
    const query = getQuery()
    const tokenParam = query.get('access_token')
    let accessToken = storage.getItem('accesstoken')
    if (accessToken === null && tokenParam === null) {
      // No AccessToken in storage or navigation params
      toast.error('ERROR: No AccessToken found')
      Errors.redirect('401')
      debugTrace('ERROR: No AccessToken found')
    }
    if (tokenParam !== null) {
      // Store AccessToken in browser
      accessToken = tokenParam
      storage.setItem('accesstoken', accessToken)
      Object.defineProperty(domoscioAccess, '_accessToken', {
        value: accessToken
      })
    }
    return accessToken
  }

  /**
   * Try to set parameters values by access token
   */
  const initialize = () => {
    // Check if client context state is empty, if true -> start Init
    if (Object.keys(client).length === 0) {
      const accessToken = getAccessToken()

      if (accessToken !== null) {
        // Start setup
        let jwt = storage.getItem('jwt') || ''
        // Call Access
        domoscioAccess.getParameters().then((parameters: TAllParameters) => {
          if (!parameters) {
            setIsError(true)
          } else if (parameters?.statusCode === 401) {
            debugTrace(parameters?.body?.message)
          } else if ('session_parameters' in parameters) {
            jwt = domUtils.encodeJWT(parameters, accessToken)
            const classicWorkflow = !(
              parameters?.session_parameters?.options?.lxp?.theme_started ||
              parameters?.session_parameters?.options?.lxp?.content_unavailable
            )
            const isUnsubscribeMode = parameters?.session_parameters?.options?.lxp?.unsubscribe
            const isScormUser = parameters?.session_parameters?.origin === 'scorm'
            const scopedLpsId =
              parameters?.session_parameters?.options?.lxp?.learning_program_student_id

            if (isScormUser && scopedLpsId) {
              domoscioAccess.getLearningProgramStudents({ id: Number(scopedLpsId) }).then(res => {
                const lpId = res[0]?.learning_program_id
                parameters.session_parameters.lpId = lpId
                applyParameters(parameters, classicWorkflow, isUnsubscribeMode)
              })
            } else {
              applyParameters(parameters, classicWorkflow, isUnsubscribeMode)
            }

            // 🚨 classic workflow => store tokens
            if (classicWorkflow) {
              storage.setItem('jwt', jwt)
            } else {
              // render static pages
              // TODO: Remove item never works here, fix it later
              // storage.removeItem('accessToken')
            }
          } else {
            Errors.redirect('500')
            debugTrace('ERROR: Invalid JWT token')
          }
        })
      }
    } else {
      debugTrace('ALERT: Client already init')
    }
  }

  /**
   * On mount || When user is set
   */
  React.useEffect(() => {
    if (user) {
      Object.keys(user).length === 0 && initialize()
    }
  }, [user])

  const init = useInitialization(user)

  const isObjectivesFetched = user.isRiseUpUser ? objectives !== null : true
  const ready = Object.keys(init?.parameters || {}).length !== 0 && isObjectivesFetched
  const loadingProcesses = [{ name: 'user', isReady: ready }]

  if (isError) {
    return <ReturnToHomeButton />
  }

  // As long as not all screens are ready, display loader
  return (
    <AppLoader
      mandatoryProcesses={loadingProcesses}
      loadingComponent={<Loader />}
      minimumLoadingTime={1000}
    >
      <ThemeProvider theme={theme}>
        {
          <div style={{ height: '100%' }} className={`${user.isRiseUpUser ? 'riseUpMode' : ''}`}>
            {children}
          </div>
        }
      </ThemeProvider>
    </AppLoader>
  )
}

export default Initializers
