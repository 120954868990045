import * as React from 'react'
import I18n from '../../locales/I18n'
import { OnboardingTooltip } from '@domoscio/domoscio-ui'
import Joyride, { CallBackProps } from 'react-joyride'
import { User } from '../../contexts/parameters/parametersWrapper'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { useRiseUpClient } from '../../hooks/useRiseUpClient'

interface Props {
  children: React.ReactNode
}

export default function ProgressTour({ ...props }: Props) {
  const { isRiseup } = useRiseUpClient()
  const user = React.useContext(User.State)
  const userDispatch = React.useContext(User.Dispatch)

  const shouldRunTour = () => {
    if (isRiseup) {
      return false
    } else {
      return !(user?.onboarding?.lxp_lock?.progress ?? false)
    }
  }
  const runTour = shouldRunTour()

  const steps = [
    {
      target: '.progressScreen',
      title: I18n.t('tour.title.step') + ' 1/3',
      content: I18n.t('tour.progress.step1.content'),
      disableBeacon: true
    },
    {
      target: '.progressScreen .progressBar',
      title: I18n.t('tour.title.step') + ' 2/3',
      content: I18n.t('tour.progress.step2.content'),
      disableBeacon: true
    },
    {
      target: '.progressScreen .dropIcon',
      title: I18n.t('tour.title.step') + ' 3/3',
      content: I18n.t('tour.progress.step3.content'),
      disableBeacon: true
    }
  ]

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status } = data
    if (status === 'finished' || action === 'reset') {
      if (runTour) {
        userDispatch({
          onboarding: domUtils.updateTour(user.onboarding, 'lxp_lock', { progress: true })
        })
      } else {
        userDispatch({ replayTour: { progress: false } })
      }
    }
  }

  const isReplay = user?.replayTour?.progress ?? false
  return (
    <>
      <Joyride
        run={runTour || isReplay}
        steps={steps}
        continuous={true}
        showSkipButton={true}
        tooltipComponent={OnboardingTooltip}
        callback={handleJoyrideCallback}
      />
      {props.children}
    </>
  )
}
