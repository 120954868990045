import React, { useState } from 'react'
import './ProgressData.scss'
import { useProgress } from '../../../contexts/progressData/progressData'
import I18n from '../../../locales/I18n'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { ProgressLevels } from './types'
import { AverageMemoryLevels } from '@domoscio/domoscio-ui'
import { useRiseUpClient } from '../../../hooks/useRiseUpClient'
import { progressFormater } from './utils'

interface Props {
  isTsr?: boolean // isTsr condition is used only on result result screen
  progressData?: ProgressLevels
  filteredSkills?: number[]
}

const ProgressData = ({ isTsr, progressData, filteredSkills }: Props) => {
  const [openSections, setOpenSections] = useState({ 1: true, 2: true }) // 1:active memo section 2: inactive memo section
  const { progress } = useProgress() // get progress data from context
  const { isRiseup, primaryColor } = useRiseUpClient()

  const actualProgressData = progressData || progress // if progress data is passed as prop use this if not use progress in context
  if (!actualProgressData) {
    return null
  }

  const formatedProgresData = progressFormater(actualProgressData, filteredSkills)

  const activeObjectives = formatedProgresData.filter(elem => elem?.isActive) // ids of active objectives
  const disabledObjective = formatedProgresData.filter(elem => !elem?.isActive) // ids of inactive objectives

  const sections = [] as Array<{ name: string; data: typeof activeObjectives }>

  if (activeObjectives.length > 0) {
    const section = {
      name: isTsr
        ? I18n.t('home.progress')
        : I18n.t('home.memo_active') + ` (${activeObjectives.length})`,
      data: activeObjectives
    }
    sections.push(section)
  }
  // if inactive objective exist, create inactive section
  if (disabledObjective.length > 0 && !isTsr) {
    const section = {
      name: I18n.t('home.memo_inactive') + ` (${disabledObjective.length})`,
      data: disabledObjective
    }
    sections.push(section)
  }

  return (
    <div className='progressData'>
      {sections.map((section, index) => {
        return (
          <div className='section' key={index}>
            <div
              className='sectionTitle'
              onClick={() => setOpenSections(prev => ({ ...prev, [index]: !prev[index] }))}
            >
              {section.name}{' '}
              {!isTsr && (
                <span>{openSections[index] ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}</span>
              )}
            </div>
            {!openSections[index] &&
              section.data.map((obj, i) => (
                <AverageMemoryLevels
                  isSectionOpen={isTsr}
                  key={i}
                  {...obj}
                  primaryColor={isRiseup ? primaryColor : undefined}
                />
              ))}
          </div>
        )
      })}
    </div>
  )
}

export default ProgressData
