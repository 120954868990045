/**
 * @file Manages the methods of the ProgressScreen screen.
 * @author Matthieu Mugnier
 */

import * as React from 'react'
import { domoscioAccess } from '../../api/domoscio_access'
import { User } from '../../contexts/parameters/parametersWrapper'
import { useScormUser } from '../../hooks/useScormUser'
import { TLoading } from '../../types/global'
import ComponentErrorWrapper from '../../components/Errors/ErrorWrappers/ComponentErrorWrapper'
import { Loader, AverageMemoryLevels } from '@domoscio/domoscio-ui'

import ProgressTour from '../../components/tours/ProgressTour'
import { ProgressLevels } from '../../components/home/progress/types'
import '../../styles/ProgressScreen.scss'
import { TApiParams } from './ReviewsContext'
import { progressFormater } from '../../components/home/progress/utils'
/**
 * Renders the Progress Screen
 *
 * @category Screens
 */

function ProgressScreen() {
  const [status, setStatus] = React.useState<TLoading>('idle')
  const [progressData, setProgressData] = React.useState<ProgressLevels | null>(null)
  const user = React.useContext(User.State)

  const learningProgramIds = useScormUser()

  const getMemorizeProgression = () => {
    const apiParams = {
      student_uid: user.uid
    } as TApiParams
    if (learningProgramIds?.learning_program_id) {
      apiParams.learning_program_id = learningProgramIds.learning_program_id
    }
    setStatus('loading')
    domoscioAccess
      .getMemorizeProgression(apiParams)
      ?.then((data: { objectives: ProgressLevels }) => {
        if (Array.isArray(data?.objectives)) {
          setProgressData(data?.objectives)
          setStatus('success')
        } else {
          setStatus('error')
        }
      })
      ?.catch(() => {
        setStatus('error')
      })
  }
  React.useEffect(() => {
    getMemorizeProgression()
    // eslint-disable-next-line
  }, [])

  if (status === 'loading' || status === 'idle' || !progressData) {
    return <Loader />
  }
  if (status === 'error') {
    return <ComponentErrorWrapper onClickRefresh={getMemorizeProgression} />
  }

  const formatedData = progressFormater(progressData)

  return (
    <div className='progressScreen'>
      <ProgressTour>
        <div style={{ marginTop: 50 }}>
          {formatedData.map((el, i) => (
            <div key={i}>
              <AverageMemoryLevels {...el} />
            </div>
          ))}
        </div>
      </ProgressTour>
    </div>
  )
}

export default ProgressScreen
