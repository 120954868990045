/**
 * @file Manages the methods of the ActionScreen screen.
 * @author Matthieu Mugnier
 */
import React from 'react'
import ActionTimeline from '../../components/home/action/ActionTimeline'
import AcceptedChallenges from '../../components/home/challenges/AcceptedChallenges'
import { useDomToolkit } from '../../styles/dom-toolkit'
import ErrorComponent from '../../components/Errors/ErrorWrappers/ComponentErrorWrapper'
import { ReviewsProvider, useHomeReviews } from './ReviewsContext'
import ActionLoading from '../../components/home/action/ActionLoading'
import { useProgress } from '../../contexts/progressData/progressData'
import { useRiseUpClient } from '../../hooks/useRiseUpClient'
import { TLoading } from '../../types/global'
import { getMemorizeProgression } from '../../core/fetchObjectiveDatas'
import ProgressData from '../../components/home/progress/ProgressData'
import { User } from '../../contexts/parameters/user'
import I18n from '../../locales/I18n'

function ActionScreen() {
  const classes = useDomToolkit()

  return (
    <div className={`ActionScreen ${classes.domMarginXLargeTop}`}>
      <ReviewsProvider>
        <ActionContent />
      </ReviewsProvider>
    </div>
  )

  function ActionContent() {
    const [progressStatus, setProgressStatus] = React.useState<TLoading>('idle')
    const { reviews, status, fetchReviews } = useHomeReviews()
    const { isRiseup } = useRiseUpClient()
    const user = React.useContext(User.State)
    const { setProgressData } = useProgress()

    React.useEffect(() => {
      setProgressStatus('loading')
      if (isRiseup && user.uid) {
        const lang = I18n.locale
        getMemorizeProgression(user.uid, lang).then(data => {
          if (data) {
            setProgressData(prev => ({ ...prev, progress: data.objectives }))
            setProgressStatus('success')
          } else {
            setProgressStatus('error')
          }
        })
      } else {
        setProgressStatus('success')
      }
      // eslint-disable-next-line
    }, [])

    // wait for fetch reviews and fetch meme levels
    if (
      status === 'loading' ||
      status === 'idle' ||
      progressStatus === 'idle' ||
      progressStatus === 'loading'
    ) {
      return <ActionLoading />
    }

    if (status === 'error') {
      return <ErrorComponent onClickRefresh={fetchReviews} />
    }

    return (
      <>
        <ActionTimeline />
        {reviews.ongoingChallenges.length > 0 && (
          <AcceptedChallenges challenges={reviews.ongoingChallenges} />
        )}
        {isRiseup && <ProgressData />}
      </>
    )
  }
}

export default ActionScreen
